@import '~@weareopenr/component-library/build/theme.scss';

.channel-container {
  display: flex;
  flex-direction: column;

}

.channel-row {
  display: flex;
  align-items: center;
  margin-bottom: $spacing-4;
}
.on-first-row {
  @extend .channel-row;
  margin-bottom: 0;
}

.channel-logo {
  width: 24px;
  height: 24px;
  margin-right: $spacing-2;
}