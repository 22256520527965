@import '~@weareopenr/component-library/build/theme.scss';

.close-button {
  right: $spacing-4;
  top: $spacing-4;
  z-index: 2;
  position: absolute;
}

.close-icon {
  color: $blueberry;
}

.image-container {
  position: relative;
  width: 33vw;
  height: 100vh;
  overflow: hidden;
  @media (max-width: 1095px) {
    display: none;
  }
}

.image {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.container {
  display: flex;
  height: 100vh;
  align-items: center;
  &::after,
  &::before {
    pointer-events: none;
    content: '';
    position: absolute;
    width: 67vw;
    height: $spacing-4;
    left: 33vw;
    z-index: 2;
  }
  &::before {
    top: 0;
    background: linear-gradient($salt, transparent);
  }
  &::after {
    bottom: 0;
    background: linear-gradient(transparent, $salt);
  }
}

.form-content {
  width: 67vw;
  height: auto;
  max-height: 100vh;
  position: relative;
  z-index: 1;
  @media (max-width: 1095px) {
    width: 100vw;
    padding: 0 $spacing-6;;
  }

  > form {
    margin: $spacing-4 auto;
    max-width: 500px;
    display: flex;
    flex-direction: column;
  }
}