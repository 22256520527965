@import '~@weareopenr/component-library/build/theme.scss';

.logo {
  height: 72px;
  width: 72px;
  margin-right: $spacing-4;
  border-radius: 8px;
}

.info-container {
  display: inherit;
  align-items: inherit;
}

.channel-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 104px;
  background-color: $salt;
  border-radius: $border-radius;
  border: 1px solid $blueberry;
  margin-bottom: $spacing-4;
  padding: $spacing-4;
}