@import '~@weareopenr/component-library/build/theme.scss';

.layout {
  min-height: 100vh;
  display: flex;
  background-color: $earl-grey;
}

.right-container {
  width: 100%;
}

.content {
  width: 100%;
  padding: $spacing-4 $spacing-6;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  overflow-x: hidden;

  @media (max-width: $desktop) {
    padding: $spacing-3 40px;
  }
}

.inner-content {
  max-width: 1120px;
  width: 100%;
}
