@import '~@weareopenr/component-library/build/theme.scss';

.logo {
  width: 72px;
  height: 72px;
  object-fit: cover;
  border-radius: 8px;
}

.spinner {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
