@use '~@weareopenr/component-library/build/theme.scss' as *;

.heading-text {
  margin-bottom: $spacing-7;
}

.text {
  margin: 2px 0;
}

.container-text {
  @extend .text;
  color: $blueberry;
}

.input {
  max-width: 500px;
  width: 100%;
  margin-bottom: $spacing-4;
}

.fail-message {
  display: flex;
  justify-content: center;
}

.credential-container {
  margin: $spacing-4 0 $spacing-4;
}

.credential-text {
  margin-bottom: $spacing-4;
}

.controls {
  display: flex;

  & >:not(:last-child) {
    margin-right: $spacing-4;
  }
}
