@import '~@weareopenr/component-library/build/theme.scss';

.text {
  margin-bottom: $spacing-8;
}

.container {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.left {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: $desktop) {
    padding: 0 $spacing-6;
    width: 100%;
  }
}

.right {
  position: relative;
  width: 50%;
  @media (max-width: $desktop) {
    display: none;
  }
}

.image {
  object-fit: cover;
  min-width: 100%;
  height: auto;
}

.login-container {
  width: 70%;
  max-width: 500px;
  @media (max-width: $desktop) {
    width: 100%;
  }
}
