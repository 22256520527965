@import '~@weareopenr/component-library/build/theme.scss';

.section {
  margin: 64px 0 0 $spacing-2;
}

.item-detail-container {
  margin-top: $spacing-2;
  display: flex;
  justify-content: space-between;

  @media (max-width: $desktop) {
    flex-direction: column;
  }
}

.logo-image {
  min-height: 324px;
  min-width: 324px;
  max-height: 324px;
  max-width: 324px;
  border-radius: $spacing-2;
  object-fit: cover;
  object-position: center;
}

.spinner {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
