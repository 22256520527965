@use '~@weareopenr/component-library/build/theme.scss' as *;

.heading-text {
  margin-bottom: $spacing-7;
}

.text {
  margin: 2px 0;
}

.container-text {
  @extend .text;
  color: $blueberry;
}

.input-name {
  max-width: 500px;
  width: 100%;
  margin-bottom: $spacing-5;

  @media (max-width: 548px) {
    width: 100%;
  }
}

.fail-message {
  display: flex;
  justify-content: center;
}